import { MENU_TOGGLE, CHANGE_ACTION } from './actionType'

const defaultStore = {
    menuToggle: false,
    action: 'normal' // normal-正常官网展示，distribute-分发展示
}

const reducer = (state = defaultStore, action) => {
    switch (action.type) {
        case MENU_TOGGLE:
            return { ...state, menuToggle: !state.menuToggle }
        case CHANGE_ACTION:
            return { ...state, action: action.action }
        default:
            return state
    }
}

export default reducer
