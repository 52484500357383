import { lazy } from "react";

const HomePage = lazy(() =>
  import(/* webpackChunkName: 'home' */ "../views/HomePage")
);
// 内容新基建
const ContentRedesignPage = lazy(() =>
  import(/* webpackChunkName: 'content' */ "@/views/ContentRedesign")
);
// 解決方案
const SolutionPage = lazy(() =>
  import(/* webpackChunkName: 'solution' */ "../views/Solution")
);

const routes = [
  { path: "home", name: "home", component: HomePage },
  { path: "product", name: "product", component: ContentRedesignPage, children: [
      {
        path: "/cms",
        name: "cms",
        component: ContentRedesignPage,
      },
      {
        path: "/application",
        name: "application",
        component: ContentRedesignPage,
      },
      {
        path: "/ccs",
        name: "ccs",
        component: ContentRedesignPage,
      },
      {
        path: "/private",
        name: "private",
        component: ContentRedesignPage,
      },
  ]},
  { path: "contech", name: "contech", component: ContentRedesignPage },
  {
    path: "solution",
    name: "solution",
    children: [
      {
        path: "/saas",
        name: "saas",
        component: SolutionPage,
      },
      {
        path: "/health",
        name: "health",
        component: SolutionPage,
      },
      {
        path: "/transport",
        name: "transport",
        component: SolutionPage,
      },
      {
        path: "/media",
        name: "media",
        component: SolutionPage,
      },
      {
        path: "/financial",
        name: "financial", // 金融保险
        component: SolutionPage,
      },
      {
        path: "/retail", // 零售连锁
        name: "retail",
        component: SolutionPage,
      },

      // 场景解决方案
      {
        path: "/webScene", // web 4-9
        name: "webScene",
        component: SolutionPage,
      },
      {
        path: "/miniProgramScene", // 小程序 4-8
        name: "miniProgramScene",
        component: SolutionPage,
      },
      {
        path: "/appScene", // app 4-7
        name: "appScene",
        component: SolutionPage,
      },
      {
        path: "/growth", // 4-6裂变
        name: "growth",
        component: SolutionPage,
      },
      {
        path: "/differentiating", // 4-5千人千面
        name: "differentiating",
        component: SolutionPage,
      },
      {
        path: "/event-marketing", // 4-4精准活动
        name: "event-marketing",
        component: SolutionPage,
      },
      {
        path: "/scrm", // 4-3私域
        name: "scrm",
        component: SolutionPage,
      },
      {
        path: "/collaboration", // 4-2总部营销
        name: "collaboration",
        component: SolutionPage,
      },
      {
        path: "/store", // 4-1连锁店
        name: "store",
        component: SolutionPage,
      },
    ],
  }
];

export default routes;
