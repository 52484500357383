import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router'
import { connect } from 'react-redux';
import { CHANGE_ACTION } from '../../store/actionType'

const AutoScrollToTop = ({ children, changeAction, action }) => {
  console.log(action, 12313214142)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  changeAction(searchParams.get('action') || action)
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return children
}

const mapStateToAction = (state) => ({
  action: state.action
})

const mapDispatchToAction = {
  changeAction: (action = 'normal') => ({ type: CHANGE_ACTION, action }),
};

export default connect(mapStateToAction, mapDispatchToAction)(AutoScrollToTop)
