import React, {
  Suspense,
  lazy
} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
// import loadable from "./utils/loadable";
// import 'animate.css' 后续再添加
import "./style/base.scss";
import "./style/App.scss";
import routes from '@/routes'
import AutoScrollToTop from '@/layout/autoScrollToTop/index.jsx'

const DefaultLayout = lazy(() =>
  import( /* webpackChunkName: 'default' */ "./layout")
);
const View404 = lazy(() => import("./views/Others/404"));

const App = () => ( <
  Suspense >
  <
  BrowserRouter basename = "/homepage" >
  <
  AutoScrollToTop >
  <
  Routes >
  <
  Route path = "/"
  element = {
    < Navigate to = "/home" / >
  } > < /Route> <
  Route path = "/"
  element = {
    < DefaultLayout / >
  } > {
    routes.map((item) => {
      if (item.children) {
        const parentPath = item.path;
        return (
          item.children.map((child) => {
            const Component = child.component;
            return ( <
              Route key = {
                child.path
              }
              path = {
                parentPath + child.path
              }
              element = {
                < Component / >
              }
              />
            );
          })
        )
      }
      const Component = item.component; // 将组件赋值给一个变量
      return ( <
        Route key = {
          item.path
        }
        path = {
          item.path
        }
        element = {
          < Component / >
        } // 使用 JSX 语法调用组件
        />
      );
    })
  } <
  /Route> <
  Route path = "/404"
  element = {
    < View404 / >
  }
  /> <
  /Routes> <
  /AutoScrollToTop> <
  /BrowserRouter> <
  /Suspense>
);

export default App;